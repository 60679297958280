// TeamsLandingPage.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Navigation from "./components/global/navigation";
import "./App.css";
import "./components/landingPages/teamsLandingPage.css";

function TeamsLandingPage() {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchTeams();
    document.title = "TeamTrivia - Daily Hockey Trivia";
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/teams`
      );
      setTeams(response.data);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  return (
    <div className='TeamsLandingPage'>
      <Navigation />
      <div className='container'>
        <h2 className='select-team-text'>Select a team</h2>
        <p className='select-sub-text'>Put your knowledge to the test</p>
        <div className='team-grid'>
          {teams.map((team) => (
            <Link key={team.code} to={`/teams/${team.code}/games`}>
              <div
                className='team-box'
                style={{
                  backgroundColor: team.primaryColor,
                  borderColor: team.secondaryColor,
                }}
              >
                <h2 style={{ color: team.textColor }}>{team.fullName}</h2>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TeamsLandingPage;
