// utils/metricUtils.js
export function sanitizeMetric(metric) {
  switch (metric) {
    case "totalGoals":
      return "total goals";
    case "totalAssists":
      return "total assists";
    case "totalPoints":
      return "total points";
    case "totalPeanltyMinutes":
      return "total penalty minutes";
    case "totalSeasons":
      return "total seasons";
    case "totalPlusMinus":
      return "total +/-";
    case "totalGamesPlayed":
      return "total games played";
    case "seasonHighGoals":
      return "season high goals";
    case "seasonHighAssists":
      return "season high assists";
    case "seasonHighPoints":
      return "season high points";
    case "seasonHighPlusMinus":
      return "season high +/-";
    case "jerseyNumber":
      return "jersey number";
    case "birthYear":
      return "birth year";
    default:
      return metric;
  }
}

export function sanitizeCondition(condition) {
  switch (condition) {
    case "birthCountryCode":
      return "Country";
    case "birthCountryContinent":
      return "Continent";
    case "jerseyNumberParity":
      return "Jersey Number";
    case "gamesPlayedWithTeam":
      return "Games Played";
    default:
      return condition;
  }
}
