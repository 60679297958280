import React, { useState, useEffect } from "react";
import axios from "axios";
import PlayerSearch from "../global/playerSearch";
import "./guess.css";

function GuessInput({
  onSelectPlayer,
  onGuessSubmit,
  maxGuesses,
  numGuesses,
  gameOver,
  teamID,
  level,
  guessedPlayerIds,
}) {
  const [clearSearchBox, setClearSearchBox] = useState(true);

  useEffect(() => {
    setClearSearchBox(numGuesses < maxGuesses && !gameOver);
  }, [numGuesses, maxGuesses, gameOver]);

  const handleSubmit = async (selectedPlayer) => {
    if (selectedPlayer && !gameOver) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/guess`,
          {
            guessPlayerId: selectedPlayer.id,
            teamCode: teamID,
            level: level, // Pass the level to the backend
          }
        );
        const guessData = response.data;
        onGuessSubmit(guessData);
        setClearSearchBox(false);
      } catch (error) {
        console.error("Error submitting guess:", error);
      }
    }
  };

  return (
    <div className='guess-form'>
      {!gameOver && (
        <PlayerSearch
          onSelectPlayer={onSelectPlayer}
          onSubmit={handleSubmit}
          clearSearchBox={clearSearchBox}
          gameOver={gameOver}
          teamID={teamID}
          level={level}
          guessedPlayerIds={guessedPlayerIds}
        />
      )}
    </div>
  );
}
export default GuessInput;
