import React from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";

function BoardGameGuesses({ gameConditions, guesses, incorrectGuesses }) {
  if (!gameConditions) {
    return <div>Loading...</div>;
  }
  const { amountNeeded } = gameConditions;
  const sortedGuesses = Array(amountNeeded).fill(null);
  guesses.forEach((guess) => {
    if (guess.rank) {
      sortedGuesses[guess.rank - 1] = guess;
    }
  });

  const wrongGuessIcons = Array(3)
    .fill(null)
    .map((_, index) => (
      <span
        key={index}
        className={`text-2xl ${
          index < incorrectGuesses ? "text-red-500" : "text-gray-500"
        }`}
      >
        X
      </span>
    ));
  return (
    <div className='board-game-guesses'>
      <div className='wrong-guesses mb-4'>{wrongGuessIcons}</div>
      <Table>
        <TableCaption>Board Game Guesses</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className='w-[100px]'>Position</TableHead>
            <TableHead>Player</TableHead>
            <TableHead className='text-right'>Metric</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedGuesses.map((guess, index) => {
            const isCorrect = guess && guess.rank === index + 1;
            return (
              <TableRow key={index}>
                <TableCell
                  className={`font-medium ${isCorrect ? "text-green-500" : ""}`}
                >
                  {index + 1}
                </TableCell>
                {guess ? (
                  <>
                    <TableCell>
                      {guess.player.firstName} {guess.player.lastName}
                    </TableCell>
                    <TableCell className='text-right'>
                      {guess.assistCount}
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>-</TableCell>
                    <TableCell className='text-right'>-</TableCell>
                  </>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default BoardGameGuesses;
