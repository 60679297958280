// src/constants/gameInstructions.js
import React from "react";

export const gameInstructions = (gameModeMessage) => (
  <>
    <p>Get six changes to guess today’s mystery player based on clues. </p>
    <ul>
      <li>
        You have six guesses to guess the mystery player. After each guess,
        you'll receive hints.
      </li>
      <li>
        Players from the same country will be marked in{" "}
        <span className='highlight-green'>green</span>, and players from a
        different country in the same continent will be marked in{" "}
        <span className='highlight-yellow'>yellow</span>.
      </li>
      <li>
        Players that play the same position will be marked in{" "}
        <span className='highlight-green'>green</span>, and players who play a
        different offensive position will be marked in{" "}
        <span className='highlight-yellow'>yellow</span>.
      </li>
      <li>
        Jersey number, first year, and points hints use arrows (⬆️for higher, ⬇️
        for lower).
      </li>
      <li>Keep guessing and using hints to find the mystery player!</li>
    </ul>
    {gameModeMessage}
  </>
);

export const lineupInstructions = (gameModeMessage) => (
  <>
    <p>
      Welcome to the Line<emp>UP</emp> Game! Here's how to play:
    </p>
    <ul>
      <li>Each day you are given a condition, a stat, and an order.</li>
      <li>
        Each player you submit must meet the condition and be play the correct
        corresponding position
      </li>
      <li>
        The designated stat must be higher than your previously guessed player.
      </li>
    </ul>
    {gameModeMessage}
  </>
);

export const playerRankingInstructions = (gameModeMessage) => (
  <>
    <p>LineUp the players in order </p>
    <ul>
      <li>
        Each day you will be given a random list of players and a differnet
        metric (goals, assists, etc.)
      </li>
      <li>
        Drag and drop the players to order the players by highest to lowest
      </li>
      <li>
        Once you've finished, submit your guess to see if you got today's LineUP
        correct.
      </li>
    </ul>
    {gameModeMessage}
  </>
);

export const comeBackMessage = (
  <>
    <p className='bottom-message'>
      A new puzzle is released daily at midnight. Come back each day for a new
      game.{" "}
    </p>
  </>
);
