// src/utils/gameModeMessage.js
export const renderGameModeMessage = (team, level) => {
  const inceptionYear = team.inceptionYear;
  const hardYear = inceptionYear < 1970 ? 1970 : inceptionYear;
  const mediumYear = inceptionYear < 2006 ? 2006 : inceptionYear;

  if (level === "hard") {
    return (
      <>
        <p>
          Game Mode: <strong>Hard</strong> - Players must have played 50 or more
          games with the {team.fullName} since <strong>{hardYear}</strong>.
        </p>
      </>
    );
  } else if (level === "medium") {
    return (
      <p>
        Game Mode: <strong>Medium</strong> - Players must have played 50 or more
        games with the {team.fullName} since <strong>{mediumYear}</strong>.
      </p>
    );
  } else {
    // If level is not provided or is falsy
    return (
      <p>
        Players must have played 50 or more games with the {team.fullName} since{" "}
        <strong>{inceptionYear}</strong>.
      </p>
    );
  }
};
