// components/HelperModal.js
import React from "react";
import Modal from "./modal.js";
import "./helperModal.css";
import { comeBackMessage } from "../../constants/gameInstructions";

function HelperModal({ isOpen, onClose, instructions }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='helper-modal'>
        <div className='close-button' onClick={onClose}>
          &times;
        </div>
        <h2>How to Play</h2>
        {instructions}
        <div className='divider-line'></div>
        {comeBackMessage}
      </div>
    </Modal>
  );
}

export default HelperModal;
