// Navigation.js
import React from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import HelperModal from "./helperModal";
import "./navigation.css";
import iconImage from "../../icon.png";
import { setHelperModalDismissed } from "../../utils/gameUtils";
import teamCodes from "../../constants/teamCodes";

function Navigation({
  instructions,
  showHelperModal,
  setShowHelperModal,
  gameType,
}) {
  const { teamID } = useParams();
  const pageTitle = teamID ? `${teamCodes[teamID].name} Trivia` : "Team Trivia";

  const handleHelperIconClick = () => {
    setShowHelperModal(true);
  };

  const handleHelperModalClose = () => {
    setShowHelperModal(false);
    setHelperModalDismissed(gameType);
  };
  const getNavTitleMargin = () => {
    if (instructions) {
      return "0px"; // Add margin on both sides if instructions exist
    } else {
      return "0 42px 0 0px"; // Add margin only on the left side
    }
  };

  return (
    <>
      <nav>
        <Link
          to='/'
          className='home-icon'
          aria-label='Go to TeamTrivia home page'
        >
          <img src={iconImage} alt='Icon' className='nav-icon' />
        </Link>
        <h1 className='nav-title' style={{ margin: getNavTitleMargin() }}>
          {pageTitle}
        </h1>
        <div className='nav-icons'>
          {instructions && (
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className='nav-icon-help'
              onClick={handleHelperIconClick}
              setShowHelperModal={setShowHelperModal}
            />
          )}
        </div>
      </nav>
      {instructions && (
        <HelperModal
          isOpen={showHelperModal}
          onClose={handleHelperModalClose}
          instructions={instructions}
        />
      )}
    </>
  );
}

export default Navigation;
