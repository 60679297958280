// components/global/modal.js
import React from "react";
import "./modal.css";

function Modal({ isOpen, onClose, title, children }) {
  if (!isOpen) {
    return null;
  }

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className='modal-overlay' onClick={handleOverlayClick}>
      <div className='modal-content'>
        {title && <h2 className='modal-title'>{title}</h2>}
        {children}
      </div>
    </div>
  );
}

export default Modal;
