// components/playerRanking/playerRankingStatsModal.js
import React, { useState, useEffect } from "react";
import Modal from "../global/modal";
import NextGame from "../global/nextGame";
import { calculateTeamStats } from "../../utils/streakUtils";
import { generateResultString } from "../../utils/playerRankingUtils";
import { teamColors } from "../../constants/teamCodes";

function PlayerRankingStatsModal({ isOpen, onClose, gameStatus, teamID }) {
  const [selectedTeam, setSelectedTeam] = useState(teamID);
  const [showCopiedNotification, setShowCopiedNotification] = useState(false);
  const teamResults = JSON.parse(localStorage.getItem("teamResults")) || {};

  useEffect(() => {
    if (isOpen) {
      setSelectedTeam(teamID);
    }
  }, [isOpen, teamID]);

  const { wins, losses, currentStreak, maxStreak, noStats } =
    calculateTeamStats(teamResults, selectedTeam, "playerRanking");

  const handleCopy = () => {
    const resultString = generateResultString(teamID);
    navigator.clipboard.writeText(resultString);
    setShowCopiedNotification(true);
    setTimeout(() => setShowCopiedNotification(false), 2000);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='helper-modal'>
        <h2>
          {gameStatus === "win" ? "Perfect Ranking!" : "Incorrect Ranking"}
        </h2>
        <NextGame />
        <div className='toggle-button'>
          <button
            className={selectedTeam === teamID ? "selected" : ""}
            onClick={() => setSelectedTeam(teamID)}
          >
            {teamID}
          </button>
          <button
            className={selectedTeam === "all" ? "selected" : ""}
            onClick={() => setSelectedTeam("all")}
          >
            All Teams
          </button>
        </div>
        {noStats ? (
          <p>No stats available.</p>
        ) : (
          <div className='stats-container'>
            <div className='stats-item'>
              <div className='stats-label'>Total Record</div>
              <div className='stats-value'>
                {wins} - {losses}
              </div>
            </div>
            <div className='stats-item'>
              <div className='stats-label'>Current Streak</div>
              <div className='stats-value'>{currentStreak}</div>
            </div>
            <div className='stats-item'>
              <div className='stats-label'>Max Streak</div>
              <div className='stats-value'>{maxStreak}</div>
            </div>
          </div>
        )}
        <button
          onClick={handleCopy}
          style={{
            backgroundColor: teamColors[teamID].primaryColor,
            color: teamColors[teamID].textColor,
          }}
        >
          {showCopiedNotification ? "Copied!" : "Copy Results"}
        </button>
      </div>
    </Modal>
  );
}

export default PlayerRankingStatsModal;
