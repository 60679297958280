import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Navigation from "./components/global/navigation";
import GameBoxSkeleton from "./components/landingPages/gameBoxSkeleton";
import "./App.css";
import "./components/landingPages/teamLandingPage.css";
import teamCodes, { teamColors } from "./constants/teamCodes";

function TeamLandingPage() {
  const { teamID } = useParams();
  const [gameData, setGameData] = useState(null);
  const teamName = teamCodes[teamID].name || "Team Trivia";
  const [teamData, setTeamData] = useState(null);

  useEffect(() => {
    document.title = `${teamName} Trivia - Test Your Knowledge | TeamTrivia.io`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        `Challenge yourself with ${teamName} trivia games on TeamTrivia and prove your fandom.`
      );
    window.scrollTo(0, 0);
  }, [teamName]);

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teams/${teamID}/games`
        );
        setGameData(response.data);
      } catch (error) {
        console.error("Error fetching game data:", error);
      }
    };

    fetchGameData();
  }, [teamID]);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teams`
        );
        const teams = response.data;
        const currentTeam = teams.find((team) => team.code === teamID);

        if (currentTeam) {
          setTeamData(currentTeam);
          const inceptionYear = currentTeam.inceptionYear;
          const hardYear = inceptionYear < 1970 ? 1970 : inceptionYear;
          const mediumYear = inceptionYear < 2006 ? 2006 : inceptionYear;
          setTeamData({ ...currentTeam, hardYear, mediumYear });
        }
      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchTeamData();
  }, [teamID]);

  const getPlayersSinceMessage = (level) => {
    if (!teamData) return "";

    const { inceptionYear, hardYear, mediumYear } = teamData;

    if (level === "hard") {
      return hardYear;
    } else if (level === "medium") {
      return mediumYear;
    } else {
      return inceptionYear;
    }
  };

  const getLevelClass = (level) => {
    if (level === "medium") {
      return "level-medium";
    } else if (level === "hard") {
      return "level-hard";
    }
    return "";
  };

  return (
    <div className='page-container'>
      <Navigation teamID={teamID} />
      {/* <div
        className='gradient-background-teamLanding'
        style={{
          background: `linear-gradient(120deg, ${
            teamColors[teamID] ? teamColors[teamID].primaryColor : "#0038ff"
          }, ${
            teamColors[teamID] ? teamColors[teamID].secondaryColor : "#0038ff"
          })`,
        }}
      ></div> */}
      <div className='container'>
        <div className='game-title-container'>
          <h1>Select a game</h1>
        </div>
        <div className='game-container'>
          {gameData ? (
            <>
              {gameData.playerRanking && (
                <a href={`/playerRanking/${teamID}`} className='game-box'>
                  <h2
                    className='game-box-top'
                    style={{
                      backgroundColor: teamColors[teamID].primaryColor,
                      color: teamColors[teamID].textColor,
                    }}
                  >
                    LineUP
                  </h2>
                  <p className='game-box-text'>
                    Rank a list of players based on a given metric.
                  </p>
                </a>
              )}
              {gameData.answers.map((level, index) => (
                <a
                  key={index}
                  href={
                    level === "N/A"
                      ? `/guesser/${teamID}`
                      : `/guesser/${teamID}?level=${level}`
                  }
                  className='game-box'
                >
                  <h2
                    className='game-box-top'
                    style={{
                      backgroundColor: teamColors[teamID].primaryColor,
                      color: teamColors[teamID].textColor,
                    }}
                  >
                    Player Guesser
                  </h2>
                  <p className='game-box-text'>
                    Get six chances to guess today's mystery player based on
                    clues. <br></br>
                    <br></br>
                    Players since{" "}
                    <strong>{" " && getPlayersSinceMessage(level)}</strong>
                  </p>
                  {level !== "N/A" && (
                    <div className={`level-chip ${getLevelClass(level)}`}>
                      {level}
                    </div>
                  )}
                </a>
              ))}
            </>
          ) : (
            Array.from({ length: 3 }).map((_, index) => (
              <GameBoxSkeleton key={index} />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default TeamLandingPage;
