import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./lineupGuesses.css";
import "./lineupConditions.css";
import { FaFlag } from "react-icons/fa";
import { sanitizeMetric } from "../../utils/metricUtils";

function LineupConditions({ teamID, onLineupConditionsLoaded }) {
  const [lineupConditions, setLineupConditions] = useState(null);
  const onLineupConditionsLoadedRef = useRef(onLineupConditionsLoaded);

  useEffect(() => {
    onLineupConditionsLoadedRef.current = onLineupConditionsLoaded;
  }, [onLineupConditionsLoaded]);

  useEffect(() => {
    const fetchLineupConditions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lineup-conditions/${teamID}`
        );
        setLineupConditions(response.data);
        onLineupConditionsLoadedRef.current(response.data);
      } catch (error) {
        console.error("Error fetching lineup conditions:", error);
      }
    };

    fetchLineupConditions();
  }, [teamID]);

  if (!lineupConditions) {
    return <div>Loading...</div>;
  }

  const { explainer, metric } = lineupConditions;

  return (
    <div className='lineup-conditions'>
      <div className='condition-info'>
        <p>
          <span className='flag-icon'>
            <FaFlag />
          </span>
          <span className='condition-metric'>
            You must guess a player for each of the corresponding positions that{" "}
            <strong>{explainer}</strong> and increase{" "}
            <strong>{sanitizeMetric(metric)}</strong> with each guess.
          </span>
        </p>
      </div>
    </div>
  );
}

export default LineupConditions;
