import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import GuessInput from "./components/guesser/GuessInput";
import GuessList from "./components/guesser/GuessList";
import Navigation from "./components/global/navigation";
import "./App.css";
import { clearOldGuesses, getCurrentLocalDate } from "./utils/dateUtils";
import { renderGameModeMessage } from "./utils/gameModeMessage";
import { gameInstructions } from "./constants/gameInstructions";
import {
  getStoredGuesses,
  setStoredGuesses,
  isGameOver,
  updateTotalRecord,
  updateTeamResults,
  hasPlayedGame,
  hasHelperModalBeenDismissed,
} from "./utils/gameUtils";
import teamCodes, { teamColors } from "./constants/teamCodes";

function GuesserGame() {
  const { teamID } = useParams();
  const location = useLocation();
  const urlLevel = new URLSearchParams(location.search).get("level");
  const level = urlLevel || "N/A"; // If there's no level in the URL, use 'N/A'
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [refreshGuesses, setRefreshGuesses] = useState(false);
  const [numGuesses, setNumGuesses] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const storedGuesses = getStoredGuesses("guesses", level);
  const teamName = teamCodes[teamID].name || "Team Trivia";
  const [showHelperModal, setShowHelperModal] = useState(false);

  useEffect(() => {
    document.title = `${teamName} Mystery Player - TeamTrivia`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        `Challenge yourself with ${teamName} mystery player trivia games on TeamTrivia and prove your fandom.`
      );
  }, [teamName]);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teams`
        );
        const teams = response.data;
        const currentTeam = teams.find((team) => team.code === teamID);

        if (currentTeam) {
          setTeamData(currentTeam);
        }
      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchTeamData();
  }, [teamID]);

  useEffect(() => {
    clearOldGuesses();
    const teamGuesses = storedGuesses[teamID] || [];
    setNumGuesses(teamGuesses.length);
    const gameOver = isGameOver(
      teamGuesses,
      6,
      (guess) => guess.clues.isCorrect
    );
    setGameOver(gameOver);

    if (!hasPlayedGame("guesser") && !hasHelperModalBeenDismissed("guesser")) {
      setShowHelperModal(true);
    }
  }, [teamID, storedGuesses]);

  const handleNumGuessesChange = (guesses) => {
    setNumGuesses(guesses.length);
    const gameOver = isGameOver(
      guesses,
      6,
      (guess) => guess && guess.clues && guess.clues.isCorrect
    );
    setGameOver(gameOver);
  };

  const handlePlayerSelect = (player) => {
    setSelectedPlayer(player);
  };

  const handleGuessSubmit = async (guessData) => {
    storedGuesses[teamID] = [...(storedGuesses[teamID] || []), guessData];
    setStoredGuesses("guesses", storedGuesses, level);
    setRefreshGuesses((prevRefreshGuesses) => !prevRefreshGuesses);
    setSelectedPlayer(null);
    const gameOver = isGameOver(
      storedGuesses[teamID],
      6,
      (guess) => guess.clues.isCorrect
    );
    setGameOver(gameOver);

    if (gameOver) {
      const isWin = guessData.clues.isCorrect;
      updateTotalRecord(isWin, "guesser");
    }
  };

  return (
    <div className='page-container'>
      <Navigation
        teamID={teamID}
        basePath='/'
        instructions={gameInstructions(
          teamData ? renderGameModeMessage(teamData, level) : null
        )}
        showHelperModal={showHelperModal}
        setShowHelperModal={setShowHelperModal}
        gameType='guesser'
      />
      <div
        className='gradient-background-guesser'
        style={{
          background: `linear-gradient(120deg, ${
            teamColors[teamID] ? teamColors[teamID].primaryColor : "#0038ff"
          }, ${
            teamColors[teamID] ? teamColors[teamID].secondaryColor : "#0038ff"
          })`,
        }}
      ></div>
      <div className='container'>
        {teamID && level ? (
          <>
            <GuessInput
              selectedPlayer={selectedPlayer}
              onSelectPlayer={handlePlayerSelect}
              onGuessSubmit={handleGuessSubmit}
              maxGuesses={6}
              numGuesses={numGuesses}
              gameOver={gameOver}
              teamID={teamID}
              level={level}
              guessedPlayerIds={storedGuesses[teamID]?.map(
                (guess) => guess.player.id
              )}
            />

            <GuessList
              refreshGuesses={refreshGuesses}
              maxGuesses={6}
              onNumGuessesChange={handleNumGuessesChange}
              gameOver={gameOver}
              teamID={teamID}
              level={level}
            />
          </>
        ) : (
          <p>No team or level selected.</p>
        )}
      </div>
    </div>
  );
}

export default GuesserGame;
