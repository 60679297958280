// BoardGameGuessInput.js
import React from "react";
import axios from "axios";
import PlayerSearch from "../global/playerSearch";

function BoardGameGuessInput({
  teamID,
  onGuessSubmit,
  guessNumber,
  gameOver,
  guessedPlayerIds,
}) {
  const handleSubmit = async (selectedPlayer) => {
    if (selectedPlayer && !gameOver) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/jeopardy-game/most-assists-player`,
          {
            teamCode: teamID,
            guessPlayerID: selectedPlayer.nhlPlayerID,
          }
        );
        const guessData = response.data;
        onGuessSubmit(guessData);
      } catch (error) {
        console.error("Error submitting guess:", error);
      }
    }
  };

  return (
    <div className='board-game-guess-input'>
      <div className='input-container'>
        {!gameOver && (
          <PlayerSearch
            onSubmit={handleSubmit}
            gameOver={gameOver}
            teamID={teamID}
            guessedPlayerIds={guessedPlayerIds}
          />
        )}
      </div>
    </div>
  );
}

export default BoardGameGuessInput;
