import React from "react";
import "./teamLandingPage.css";

const GameBoxSkeleton = () => {
  return (
    <div className='game-box skeleton'>
      <div className='game-box-top skeleton-top'></div>
      <div className='game-box-text skeleton-text'></div>
    </div>
  );
};

export default GameBoxSkeleton;
