// components/global/Footer.js
import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <p className='disclaimer'>
          Disclaimer: TeamTrivia does not claim ownership of any team names,
          logos, or color schemes presented on this website. All such names and
          color schemes are displayed solely for informational purposes and
          remain the intellectual property of their respective leagues, teams,
          ownership groups, or organizations.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
