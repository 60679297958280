// components/playerRanking/RankingBubble.js
import React from "react";
import "./playerRanking.css";

const RankingBubble = ({
  player,
  rank,
  isCorrect,
  metric,
  correctPosition,
}) => {
  const defaultBubbleColor = "#FFF";
  const bubbleColor =
    isCorrect !== null
      ? isCorrect
        ? "#4CAF50"
        : "#F44336"
      : defaultBubbleColor;
  const rankingNumberColor = isCorrect !== null ? "#FFF" : "#000";

  return (
    <div className='ranking-bubble' style={{ backgroundColor: bubbleColor }}>
      <div className='ranking-info'>
        <span className='ranking-number' style={{ color: rankingNumberColor }}>
          {isCorrect !== null ? (isCorrect ? rank : correctPosition) : rank}
        </span>
        {metric && <span className='ranking-metric'>{metric}</span>}
      </div>
    </div>
  );
};
export default RankingBubble;
