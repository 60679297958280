function formatPosition(position) {
  switch (position) {
    case "L":
      return "LW";
    case "R":
      return "RW";
    default:
      return position;
  }
}

export { formatPosition };
