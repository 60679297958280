import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./playerSearch.css";

function PlayerSearch({
  onSubmit,
  clearSearchBox,
  gameOver,
  teamID,
  level,
  guessedPlayerIds,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const searchRef = useRef(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchPlayers = async () => {
        try {
          const minSeasonYear = level === "medium" ? 2006 : level || 2006;
          let apiUrl = `${process.env.REACT_APP_API_URL}/players/search?query=${searchTerm}&teamCode=${teamID}&minGamesPlayed=50&minSeasonYear=${minSeasonYear}`;

          if (guessedPlayerIds && guessedPlayerIds.length > 0) {
            apiUrl += `&guessedPlayerIds=${guessedPlayerIds.join(",")}`;
          }

          const response = await axios.get(apiUrl);
          setSearchResults(response.data);
        } catch (error) {
          console.error("Error searching players:", error);
        }
      };
      if (searchTerm) {
        fetchPlayers();
      } else {
        setSearchResults([]);
      }
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, teamID, level, guessedPlayerIds]);

  useEffect(() => {
    if (clearSearchBox || gameOver) {
      setSearchTerm("");
      setSelectedPlayer(null);
    }
  }, [clearSearchBox, gameOver]);

  const handleSelectPlayer = (player) => {
    setSelectedPlayer(player);
    setSearchTerm(`${player.firstName} ${player.lastName}`);
    setShowDropdown(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedPlayer && !gameOver) {
      onSubmit(selectedPlayer);
      setSelectedPlayer(null);
      setSearchTerm("");
    }
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={searchRef} className='player-search'>
      {gameOver ? (
        <div className='game-over-message'>Game Over</div>
      ) : (
        <div className='search-container'>
          <input
            type='text'
            placeholder='Search players...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setShowDropdown(true)}
            disabled={gameOver}
          />
          {showDropdown && !gameOver && (
            <div className='search-results'>
              {searchResults.map((player) => (
                <div
                  key={player.id}
                  className='search-result'
                  onClick={() => handleSelectPlayer(player)}
                >
                  {player.firstName} {player.lastName}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {!gameOver && (
        <button
          className={`player-search-submit ${selectedPlayer ? "" : "disabled"}`}
          type='submit'
          onClick={handleSubmit}
          disabled={!selectedPlayer}
        >
          Submit
        </button>
      )}
    </div>
  );
}

export default PlayerSearch;
