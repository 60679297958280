import React, { useState, useEffect } from "react";
import Modal from "../global/modal";
import "../global/modal.css";
import NextGame from "../global/nextGame";
import { calculateTeamStats } from "../../utils/streakUtils";
import { teamColors } from "../../constants/teamCodes";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FaShareAlt } from "react-icons/fa";
import teamCodes from "../../constants/teamCodes";

function StatisticsModal({
  isOpen,
  onClose,
  gameStatus,
  maxGuesses,
  generateResultString,
  rank,
}) {
  const { teamID } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const level = urlParams.get("level") || "N/A";
  const [selectedTeam, setSelectedTeam] = useState(teamID);
  const teamResults = JSON.parse(localStorage.getItem("teamResults")) || {};
  const [showCopiedNotification, setShowCopiedNotification] = useState(false);
  const resultString = generateResultString();
  const [correctPlayer, setCorrectPlayer] = useState(null);
  const isWebShareSupported = !!navigator.share;
  const [teamRank, setTeamRank] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setSelectedTeam(teamID);
      if (rank === undefined) {
        fetchTeamRank();
      }
    }
  }, [isOpen, teamID, rank]);

  const fetchTeamRank = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/game-results/most-wins?timePeriod=today`
      );
      const sortedData = response.data.sort(
        (a, b) => b.correctCount - a.correctCount
      );
      const teamIndex = sortedData.findIndex(
        (item) => item.teamCode === teamID
      );
      if (teamIndex !== -1) {
        setTeamRank(teamIndex + 1);
      }
    } catch (error) {
      console.error("Error fetching team rank:", error);
    }
  };

  const handleShare = async () => {
    if (isWebShareSupported) {
      try {
        await navigator.share({
          text: resultString,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    }
  };

  useEffect(() => {
    const fetchCorrectPlayer = async () => {
      if (isOpen && gameStatus === "loss") {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/answers/player-name?teamCode=${teamID}&level=${level}`
          );
          setCorrectPlayer(response.data.playerName);
        } catch (error) {
          console.error("Error fetching correct player:", error);
        }
      }
    };

    fetchCorrectPlayer();
  }, [isOpen, gameStatus, teamID, level]);

  const handleCopy = () => {
    navigator.clipboard.writeText(resultString);
    setShowCopiedNotification(true);
    setTimeout(() => setShowCopiedNotification(false), 2000);
  };

  const calculateStats = () => {
    const {
      played,
      wins,
      losses,
      avgGuesses,
      currentStreak,
      maxStreak,
      noStats,
    } = calculateTeamStats(teamResults, selectedTeam, "guesser");

    return {
      played,
      wins,
      losses,
      avgGuesses,
      currentStreak,
      maxStreak,
      noStats,
    };
  };

  const { wins, losses, avgGuesses, currentStreak, maxStreak, noStats } =
    calculateStats();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='helper-modal'>
        <h2>
          {gameStatus === "loss" ? "Incorrect, back to the bench" : "Goal"}
        </h2>
        {gameStatus === "loss" && correctPlayer && (
          <div className='correct-player-name'>
            <h3>Correct Player: {correctPlayer}</h3>
          </div>
        )}
        {(rank !== undefined ? rank : teamRank) !== null && (
          <p>
            The {teamCodes[teamID].shortName} are ranked{" "}
            <strong>#{rank !== undefined ? rank : teamRank}</strong> for the
            day. Share with fellow fans to support the{" "}
            {teamCodes[teamID].shortName}.
          </p>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          <button
            onClick={isWebShareSupported ? handleShare : handleCopy}
            style={{
              backgroundColor: teamColors[teamID].primaryColor,
              color: teamColors[teamID].textColor,
            }}
          >
            {isWebShareSupported && showCopiedNotification
              ? "Shared!"
              : isWebShareSupported
              ? "Share Results"
              : showCopiedNotification
              ? "Copied!"
              : "Copy Results"}
            {isWebShareSupported && " "}
            {isWebShareSupported && <FaShareAlt />}
          </button>
        </div>
        {noStats ? (
          <p>No stats available.</p>
        ) : (
          <>
            <div className='stats-container'>
              <div className='stats-item'>
                <div className='stats-label'>Total Record</div>
                <div className='stats-value'>
                  {wins} - {losses}
                </div>
              </div>
              <div className='stats-item'>
                <div className='stats-label'>Average Guesses</div>
                <div className='stats-value'>{avgGuesses}</div>
              </div>
              <div className='stats-item'>
                <div className='stats-label'>Current Streak</div>
                <div className='stats-value'>{currentStreak}</div>
              </div>
              <div className='stats-item'>
                <div className='stats-label'>Max Streak</div>
                <div className='stats-value'>{maxStreak}</div>
              </div>
            </div>
          </>
        )}
        <NextGame />
      </div>
    </Modal>
  );
}

export default StatisticsModal;
