// BoardGameConditions.js
import React from "react";

function BoardGameConditions({ gameConditions }) {
  if (!gameConditions) {
    return <div>Loading...</div>;
  }

  const { player, amountNeeded } = gameConditions;

  return (
    <div className='board-game-conditions'>
      <p>
        Guess the {amountNeeded} players who assisted {player.firstName}{" "}
        {player.lastName} the most.
      </p>
    </div>
  );
}

export default BoardGameConditions;
