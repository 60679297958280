// playerRankingUtils.js
import { getCurrentLocalDate } from "./dateUtils";

export const getStoredGuesses = (key, teamID) => {
  const currentDate = getCurrentLocalDate();
  const storedGuesses = JSON.parse(localStorage.getItem(key)) || {};
  return storedGuesses[currentDate]?.[teamID] || [];
};

export const setStoredGuesses = (key, guesses, teamID, isCorrect) => {
  const currentDate = getCurrentLocalDate();
  const storedGuesses = JSON.parse(localStorage.getItem(key)) || {};
  storedGuesses[currentDate] = storedGuesses[currentDate] || {};
  storedGuesses[currentDate][teamID] = {
    guesses,
    isCorrect,
  };
  localStorage.setItem(key, JSON.stringify(storedGuesses));
};

export const clearOldGuesses = () => {
  const currentDate = getCurrentLocalDate();
  const storedGuesses =
    JSON.parse(localStorage.getItem("playerRankingGuess")) || {};
  const updatedGuesses = {};

  if (storedGuesses[currentDate]) {
    updatedGuesses[currentDate] = storedGuesses[currentDate];
  }

  localStorage.setItem("playerRankingGuess", JSON.stringify(updatedGuesses));
};

export const generateResultString = (teamID) => {
  const currentDate = getCurrentLocalDate();
  const storedGuesses =
    JSON.parse(localStorage.getItem("playerRankingGuess")) || {};
  const teamGuesses = storedGuesses[currentDate]?.[teamID]?.guesses || [];

  const emojiResults = teamGuesses
    .map((guess) => (guess.isCorrect ? "✅" : "❌"))
    .join("");

  const correctPositionEmojis = teamGuesses
    .map((guess) => {
      const position = guess.player.correctPosition;
      switch (position) {
        case 1:
          return "1️⃣";
        case 2:
          return "2️⃣";
        case 3:
          return "3️⃣";
        case 4:
          return "4️⃣";
        case 5:
          return "5️⃣";
        case 6:
          return "6️⃣";
        case 7:
          return "7️⃣";
        case 8:
          return "8️⃣";
        case 9:
          return "9️⃣";
        default:
          return "🔢";
      }
    })
    .join("");

  const resultString = `${teamID} Player Ranking ${new Date().toLocaleDateString()}\n\n${emojiResults}\n${correctPositionEmojis}\n\nPlay today at teamtrivia.io`;

  return resultString;
};
