import { getCurrentLocalDate } from "./dateUtils";

export const getStoredGuesses = (key, teamID) => {
  const currentDate = getCurrentLocalDate();
  const storedData = JSON.parse(localStorage.getItem(key)) || {};
  return {
    guesses: storedData[currentDate]?.[teamID]?.guesses || [],
    incorrectGuesses: storedData[currentDate]?.[teamID]?.incorrectGuesses || 0,
  };
};

export const setStoredGuesses = (key, guesses, incorrectGuesses, teamID) => {
  const currentDate = getCurrentLocalDate();
  const storedData = JSON.parse(localStorage.getItem(key)) || {};
  storedData[currentDate] = storedData[currentDate] || {};
  storedData[currentDate][teamID] = {
    guesses,
    incorrectGuesses,
  };
  localStorage.setItem(key, JSON.stringify(storedData));
};

export const isGameOver = (
  guesses,
  maxGuesses,
  wrongGuesses,
  maxWrongGuesses
) => {
  const correctGuesses = guesses.filter((guess) => guess.rank !== undefined);
  return correctGuesses.length >= maxGuesses || wrongGuesses >= maxWrongGuesses;
};

export const clearOldGuesses = () => {
  const currentDate = getCurrentLocalDate();
  const storedGuesses =
    JSON.parse(localStorage.getItem("boardGameGuesses")) || {};
  const updatedGuesses = {};
  if (storedGuesses[currentDate]) {
    updatedGuesses[currentDate] = storedGuesses[currentDate];
  }
  localStorage.setItem("boardGameGuesses", JSON.stringify(updatedGuesses));
};

export const generateResultString = (teamID, guesses) => {
  const currentDate = getCurrentLocalDate();
  const emojiResults = guesses
    .map((guess) => (guess.rank === guesses.indexOf(guess) + 1 ? "✅" : "❌"))
    .join("");
  const resultString = `${teamID} Board Game ${new Date().toLocaleDateString()}\n\n${emojiResults}\n\nPlay today at teamtrivia.io`;
  return resultString;
};
