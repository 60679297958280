// LineupGame.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Navigation from "./components/global/navigation";
import LineupConditions from "./components/lineup/lineupConditions";
import LineupGuessInput from "./components/lineup/lineupGuessInput";
import LineupGuesses from "./components/lineup/lineupGuesses";
import { clearOldLineupGuesses } from "./utils/dateUtils";
import { renderGameModeMessage } from "./utils/gameModeMessage";
import { lineupInstructions } from "./constants/gameInstructions";
import LineupStatsModal from "./components/lineup/lineupStatsModal";
import {
  getStoredGuesses,
  setStoredGuesses,
  isGameOver,
  updateTotalRecord,
  updateTeamResults,
  clearGuesses,
} from "./utils/gameUtils";
import teamCodes from "./constants/teamCodes";

function LineupGame() {
  const { teamID } = useParams();
  const [gameOver, setGameOver] = useState(false);
  const [lineupConditions, setLineupConditions] = useState(null);
  const [guesses, setGuesses] = useState([]);
  const [currentMetric, setCurrentMetric] = useState(0);
  const [isCleared, setIsCleared] = useState(false);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [showShareButton, setShowShareButton] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const teamName = teamCodes[teamID].name || "Team Trivia";

  useEffect(() => {
    document.title = `${teamName} Lineup - TeamTrivia`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        `Challenge yourself with ${teamName} lineup trivia games on TeamTrivia and prove your fandom.`
      );
  }, [teamName]);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teams`
        );
        const teams = response.data;
        const currentTeam = teams.find((team) => team.code === teamID);

        if (currentTeam) {
          setTeamData(currentTeam);
          document.title = `${currentTeam.fullName} Lineup - TeamTrivia`;
        }
      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchTeamData();
  }, [teamID]);

  useEffect(() => {
    clearOldLineupGuesses();
    const storedGuesses = getStoredGuesses("lineupGuess");
    const teamGuesses = storedGuesses[teamID] || [];

    const gameOver = isGameOver(teamGuesses, 5, (guess) => !guess.isCorrect);

    setShowStatsModal(gameOver);
    setGuesses(teamGuesses);
    setGameOver(gameOver);
    setShowShareButton(gameOver);
    setCurrentMetric(teamGuesses[teamGuesses.length - 1]?.player.metric || 0);
  }, [teamID]);

  const handleCloseStatsModal = () => {
    setShowStatsModal(false);
  };

  const handleShare = () => {
    setShowStatsModal(true);
  };

  const handleGuessSubmit = (guessData) => {
    const storedGuesses = getStoredGuesses("lineupGuess");
    storedGuesses[teamID] = [...(storedGuesses[teamID] || []), guessData];
    setStoredGuesses("lineupGuess", storedGuesses);
    setGuesses(storedGuesses[teamID]);

    const gameOver = isGameOver(
      storedGuesses[teamID],
      5,
      (guess) => !guess.isCorrect
    );

    setShowStatsModal(gameOver);
    setGameOver(gameOver);
    setCurrentMetric(guessData.player.metric);
    setIsCleared(false);

    if (gameOver) {
      updateTotalRecord(guessData.isCorrect, "lineup");
      updateTeamResults(
        teamID,
        guessData.isCorrect,
        storedGuesses[teamID],
        "lineup"
      );
    }
  };

  const handleClearGuesses = () => {
    clearGuesses("lineupGuess", teamID);
    setGuesses([]);
    setGameOver(false);
    setCurrentMetric(0);
    setIsCleared(true);
  };

  const handleLineupConditionsLoaded = (conditions) => {
    setLineupConditions(conditions);
  };

  return (
    <div className='LineupGame'>
      <Navigation
        teamID={teamID}
        basePath='/lineup'
        instructions={lineupInstructions(
          teamData ? renderGameModeMessage(teamData) : null
        )}
      />
      <div className='container'>
        <LineupConditions
          teamID={teamID}
          onLineupConditionsLoaded={handleLineupConditionsLoaded}
        />
        <LineupGuessInput
          teamID={teamID}
          onGuessSubmit={handleGuessSubmit}
          guessNumber={guesses.length + 1}
          currentMetric={currentMetric}
          gameOver={gameOver}
          guessedPlayerIds={guesses.map((guess) => guess.player.id)}
        />
        <LineupGuesses
          lineupConditions={lineupConditions}
          guesses={guesses}
          isCleared={isCleared}
        />{" "}
        <LineupStatsModal
          isOpen={showStatsModal}
          onClose={handleCloseStatsModal}
          gameStatus={
            gameOver && guesses.every((guess) => guess.isCorrect)
              ? "win"
              : "loss"
          }
          teamID={teamID}
        />
        {showShareButton && (
          <button className='share-button' onClick={handleShare}>
            Share Results
          </button>
        )}
        {gameOver && (
          <button className='clear-button' onClick={handleClearGuesses}>
            Clear Guesses
          </button>
        )}
      </div>
    </div>
  );
}

export default LineupGame;
