// components/lineup/LineupGuesses.js
import React from "react";
import { sanitizeMetric, sanitizeCondition } from "../../utils/metricUtils";
import { toTitleCase } from "../../utils/textUtils";

function LineupGuesses({ lineupConditions, guesses, isCleared }) {
  const positions = lineupConditions
    ? lineupConditions.positionOrder.split("-")
    : [];

  const getErrorMessage = (guess) => {
    if (!guess.meetsPosition) {
      return "Incorrect position.";
    } else if (!guess.meetsCondition) {
      const sanitizedConditionType = sanitizeCondition(
        lineupConditions.conditionType
      );
      return `${sanitizedConditionType}: ${guess.playerConditionValue}`;
    } else if (!guess.meetsMetric) {
      const sanitizedMetric = sanitizeMetric(lineupConditions.metric);
      return `${toTitleCase(sanitizedMetric)}: ${guess.player.metric}`;
    }
    return "";
  };

  return (
    <div className='circles-container'>
      {positions.map((position, index) => {
        const guess = guesses[index];
        const isCorrect = guess && guess.isCorrect;
        const errorMessage = guess && !isCorrect ? getErrorMessage(guess) : "";

        return (
          <div key={index} className='circle-row'>
            <div
              className={`circle ${
                isCleared || !guess ? "" : isCorrect ? "correct" : "incorrect"
              }`}
            >
              <div className='position'>{position}</div>
            </div>
            {guess && !isCleared && (
              <div className='player-info'>
                <div className='player-name'>
                  {guess.player.firstName} {guess.player.lastName}
                </div>
                <div className='metric'>{guess.player.metric}</div>
                {!isCorrect && (
                  <div className='error-message'>{errorMessage}</div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default LineupGuesses;
