import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./nextGame.css";
import { getCurrentLocalDate } from "../../utils/dateUtils";

function NextGame() {
  const { teamID } = useParams();
  // const [countdown, setCountdown] = useState(null);
  const [availableGames, setAvailableGames] = useState([]);

  useEffect(() => {
    const fetchAvailableGames = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teams/${teamID}/games`
        );
        const gameData = response.data;
        const currentDate = getCurrentLocalDate();

        const teamResults =
          JSON.parse(localStorage.getItem("teamResults")) || {};
        const teamLineupResults = teamResults[teamID]?.lineup || [];
        const lineupGameOver = teamLineupResults.some(
          (result) => result.date === currentDate
        );

        const teamPlayerRankingResults =
          teamResults[teamID]?.playerRanking || [];
        const playerRankingGameOver = teamPlayerRankingResults.some(
          (result) => result.date === currentDate
        );

        const availableGames = [];

        if (gameData.lineUp && !lineupGameOver) {
          availableGames.push({ name: "Lineup", path: `/lineup/${teamID}` });
        }

        if (gameData.playerRanking && !playerRankingGameOver) {
          availableGames.push({
            name: "Player Ranking",
            path: `/playerRanking/${teamID}`,
          });
        }

        const guesserLevels = gameData.answers;
        if (guesserLevels.length > 0) {
          const level = guesserLevels[0];
          const guesserGameOver = teamResults[teamID]?.guesser?.[level]?.some(
            (result) => result.date === currentDate
          );

          if (!guesserGameOver) {
            availableGames.push({
              name: "Mystery Player",
              path: `/teams/${teamID}/games`,
            });
          }
        }

        setAvailableGames(availableGames);
      } catch (error) {
        console.error("Error fetching available games:", error);
      }
    };

    fetchAvailableGames();
  }, [teamID]);

  useEffect(() => {
    const nextGameTime = new Date();
    nextGameTime.setHours(24, 0, 0, 0); // Set to midnight EST

    const timer = setInterval(() => {
      const newTimeDiff = nextGameTime.getTime() - Date.now();
      // setCountdown(formatCountdown(newTimeDiff));

      if (newTimeDiff <= 0) {
        clearInterval(timer);
        // setCountdown("Next game is available!");
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // const formatCountdown = (timeDiff) => {
  //   const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  //   const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  //   const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  //   const formattedHours = hours.toString().padStart(2, "0");
  //   const formattedMinutes = minutes.toString().padStart(2, "0");
  //   const formattedSeconds = seconds.toString().padStart(2, "0");

  //   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  // };

  const getAvailableGameMessage = () => {
    if (availableGames.length === 0) {
      return null;
    }

    const gameLinks = availableGames.map((game, index) => (
      <React.Fragment key={game.path}>
        <a href={game.path}>{game.name}</a>
        {index < availableGames.length - 1 && " or "}
      </React.Fragment>
    ));

    return (
      <>
        While you wait, take a shot at today's: {gameLinks} game
        {availableGames.length > 1 ? "s" : ""}.
      </>
    );
  };

  return (
    <div className='next-game'>
      {/* <p>Next shift in:</p>
      {countdown && (
        <div className='countdown'>
          <span>{countdown}</span>
        </div>
      )} */}
      {getAvailableGameMessage() && <p>{getAvailableGameMessage()}</p>}
    </div>
  );
}

export default NextGame;
