export const calculateCurrentStreak = (results) => {
  let streak = 0;
  for (let i = results.length - 1; i >= 0; i--) {
    if (results[i].result === "win") {
      streak++;
    } else {
      break;
    }
  }
  return streak;
};

export const calculateMaxStreak = (results) => {
  let maxStreak = 0;
  let currentStreak = 0;
  for (let i = 0; i < results.length; i++) {
    if (results[i].result === "win") {
      currentStreak++;
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 0;
    }
  }
  return maxStreak;
};

export const calculateTotalRecord = (results) => {
  const played = results.length;
  const wins = results.filter((result) => result.result === "win").length;
  const losses = played - wins;

  return {
    played,
    wins,
    losses,
  };
};

export const calculateTeamStats = (teamResults, teamID, gameType) => {
  const results =
    teamID === "all"
      ? Object.values(teamResults)
          .flatMap((team) => team[gameType])
          .filter(Boolean)
      : (teamResults[teamID]?.[gameType] || []).filter(Boolean);

  if (results.length === 0) {
    return {
      played: 0,
      wins: 0,
      losses: 0,
      avgGuesses: 0,
      currentStreak: 0,
      maxStreak: 0,
      noStats: true,
    };
  }

  const { played, wins, losses } = calculateTotalRecord(results);
  const totalGuesses = results.reduce((sum, result) => sum + result.guesses, 0);
  const avgGuesses = played > 0 ? (totalGuesses / played).toFixed(2) : 0;
  const currentStreak = calculateCurrentStreak(results);
  const maxStreak = calculateMaxStreak(results);

  return {
    played,
    wins,
    losses,
    avgGuesses,
    currentStreak,
    maxStreak,
    noStats: false,
  };
};
