function getCurrentLocalDate() {
  const currentDate = new Date();
  const localDate = new Date(
    currentDate.getTime() - currentDate.getTimezoneOffset() * 60000
  );
  return localDate.toISOString().slice(0, 10);
}

export { getCurrentLocalDate };

export const clearOldLineupGuesses = () => {
  const currentDate = getCurrentLocalDate();
  const storedGuesses = JSON.parse(localStorage.getItem("lineupGuess")) || {};
  const updatedGuesses = {};

  if (storedGuesses[currentDate]) {
    updatedGuesses[currentDate] = storedGuesses[currentDate];
  }

  localStorage.setItem("lineupGuess", JSON.stringify(updatedGuesses));
};

export const clearOldGuesses = () => {
  const currentDate = getCurrentLocalDate();
  const storedGuesses = JSON.parse(localStorage.getItem("guesses")) || {};
  const updatedGuesses = {};

  if (storedGuesses[currentDate]) {
    updatedGuesses[currentDate] = storedGuesses[currentDate];
  }

  localStorage.setItem("guesses", JSON.stringify(updatedGuesses));
};
