import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import GuesserGame from "./guesserGame";
import LineupGame from "./lineupGame";
import TeamLandingPage from "./teamLandingPage";
import TeamsLandingPage from "./teamsLandingPage";
import reportWebVitals from "./reportWebVitals";
import PlayerRankingGame from "./playerRanking";
import Footer from "./components/global/footer";
import "./App.css";
import BoardGame from "./components/boardGame/boardGame";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <div className='App'>
      <Routes>
        <Route path='/' element={<TeamsLandingPage />} />
        <Route path='/guesser/:teamID' element={<GuesserGame />} />
        <Route path='/teams/:teamID/games' element={<TeamLandingPage />} />
        <Route path='/playerRanking/:teamID' element={<PlayerRankingGame />} />
        <Route path='/lineup/:teamID' element={<LineupGame />} />
        <Route path='/boardGame/:teamID' element={<BoardGame />} />
      </Routes>
    </div>
    <Footer />
  </Router>
);

reportWebVitals();
