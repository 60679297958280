// gameUtils.js
import { getCurrentLocalDate } from "./dateUtils";

export const getStoredGuesses = (key, level) => {
  const currentDate = getCurrentLocalDate();
  const storedGuesses = JSON.parse(localStorage.getItem(key)) || {};
  return storedGuesses[currentDate]?.[level] || {};
};

export const setStoredGuesses = (key, guesses, level) => {
  const currentDate = getCurrentLocalDate();
  const storedGuesses = JSON.parse(localStorage.getItem(key)) || {};
  storedGuesses[currentDate] = storedGuesses[currentDate] || {};
  storedGuesses[currentDate][level] = guesses;
  localStorage.setItem(key, JSON.stringify(storedGuesses));
};
export const isGameOver = (guesses, maxGuesses, checkCorrect) => {
  return guesses.length >= maxGuesses || guesses.some(checkCorrect);
};

export const updateTotalRecord = (isCorrect, gameType) => {
  const totalRecord = JSON.parse(localStorage.getItem("totalRecord")) || {
    overall: { wins: 0, losses: 0 },
    guesser: { wins: 0, losses: 0 },
    lineup: { wins: 0, losses: 0 },
    playerRanking: { wins: 0, losses: 0 },
  };

  if (isCorrect) {
    totalRecord.overall.wins += 1;
    totalRecord[gameType].wins += 1;
  } else {
    totalRecord.overall.losses += 1;
    totalRecord[gameType].losses += 1;
  }

  localStorage.setItem("totalRecord", JSON.stringify(totalRecord));
};

export const updateTeamResults = (teamID, isCorrect, guesses, gameType) => {
  const currentDate = getCurrentLocalDate();
  const teamResults = JSON.parse(localStorage.getItem("teamResults")) || {};

  teamResults[teamID] = teamResults[teamID] || {
    guesser: [],
    lineup: [],
    playerRanking: [],
  };

  teamResults[teamID][gameType].push({
    date: currentDate,
    result: isCorrect ? "win" : "loss",
    guesses: guesses.filter((guess) => guess !== null).length,
  });

  localStorage.setItem("teamResults", JSON.stringify(teamResults));
};

export const clearGuesses = (key, teamID, level) => {
  const currentDate = getCurrentLocalDate();
  const storedGuesses = JSON.parse(localStorage.getItem(key)) || {};
  if (
    storedGuesses[currentDate] &&
    storedGuesses[currentDate][level] &&
    storedGuesses[currentDate][level][teamID]
  ) {
    storedGuesses[currentDate][level][teamID] = [];
    localStorage.setItem(key, JSON.stringify(storedGuesses));
  }
};

export const hasPlayedGame = (gameType) => {
  const storedGuesses = JSON.parse(localStorage.getItem("guesses")) || {};
  const storedPlayerRankings =
    JSON.parse(localStorage.getItem("playerRanking")) || {};

  if (gameType === "guesser") {
    return Object.values(storedGuesses).some((dateGuesses) =>
      Object.values(dateGuesses).some((teamGuesses) =>
        Object.values(teamGuesses).some((guesses) => guesses.length > 0)
      )
    );
  } else if (gameType === "playerRanking") {
    return Object.values(storedPlayerRankings).some((dateGuesses) =>
      Object.values(dateGuesses).some(
        (teamGuesses) => teamGuesses.guesses && teamGuesses.guesses.length > 0
      )
    );
  }

  return false;
};

export const hasHelperModalBeenDismissed = (gameType) => {
  return localStorage.getItem(`${gameType}HelperModalDismissed`) === "true";
};

export const setHelperModalDismissed = (gameType) => {
  localStorage.setItem(`${gameType}HelperModalDismissed`, "true");
};
