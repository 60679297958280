import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import BoardGameConditions from ".//boardGameConditions";
import BoardGameGuessInput from "./boardGameInput";
import BoardGameGuesses from "./boardGameGuesses";
import {
  getStoredGuesses,
  setStoredGuesses,
  isGameOver,
} from "../../utils/boardGameUtils";

function BoardGame() {
  const { teamID } = useParams();
  const [gameConditions, setGameConditions] = useState(null);
  const [guesses, setGuesses] = useState([]);
  const [incorrectGuesses, setIncorrectGuesses] = useState(0);

  useEffect(() => {
    const fetchGameConditions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/jeopardy-game/game-conditions?teamCode=${teamID}`
        );
        setGameConditions(response.data);
      } catch (error) {
        console.error("Error fetching game conditions:", error);
      }
    };
    fetchGameConditions();
    const { guesses: storedGuesses, incorrectGuesses: storedIncorrectGuesses } =
      getStoredGuesses("boardGameGuesses", teamID);
    setGuesses(storedGuesses);
    setIncorrectGuesses(storedIncorrectGuesses);
  }, [teamID]);

  const handleGuessSubmit = (guessData) => {
    const updatedGuesses = [...guesses, guessData];
    let updatedIncorrectGuesses = incorrectGuesses;
    if (guessData.message === "Incorrect guess") {
      updatedIncorrectGuesses += 1;
    }
    setGuesses(updatedGuesses);
    setIncorrectGuesses(updatedIncorrectGuesses);
    setStoredGuesses(
      "boardGameGuesses",
      updatedGuesses,
      updatedIncorrectGuesses,
      teamID
    );
  };

  const gameOver = isGameOver(
    guesses,
    gameConditions?.amountNeeded,
    incorrectGuesses,
    3
  );

  return (
    <div className='BoardGame'>
      <div className='container'>
        <BoardGameConditions gameConditions={gameConditions} />
        <BoardGameGuessInput
          teamID={teamID}
          onGuessSubmit={handleGuessSubmit}
          guessNumber={guesses.length + 1}
          gameOver={gameOver}
        />
        <BoardGameGuesses
          gameConditions={gameConditions}
          guesses={guesses}
          incorrectGuesses={incorrectGuesses}
        />
      </div>
    </div>
  );
}

export default BoardGame;
