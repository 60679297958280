import React, { useState, useEffect } from "react";
import Modal from "../global/modal";
import "../global/modal.css";
import { getCurrentLocalDate } from "../../utils/dateUtils";
import NextGame from "../global/nextGame";
import { calculateTeamStats } from "../../utils/streakUtils";

function LineupStatsModal({ isOpen, onClose, gameStatus, teamID }) {
  const [selectedTeam, setSelectedTeam] = useState(teamID);
  const [showCopiedNotification, setShowCopiedNotification] = useState(false);
  const teamResults = JSON.parse(localStorage.getItem("teamResults")) || {};

  useEffect(() => {
    if (isOpen) {
      setSelectedTeam(teamID);
    }
  }, [isOpen, teamID]);

  const generateResultString = () => {
    const currentDate = getCurrentLocalDate();
    const storedGuesses = JSON.parse(localStorage.getItem("lineupGuess")) || {};
    const teamGuesses = storedGuesses[currentDate]?.[teamID] || [];
    const emojiResults = teamGuesses
      .map((guess) => (guess.isCorrect ? "🟢" : "🔴"))
      .join("");
    const metricValues = teamGuesses
      .map((guess) => {
        const metric = guess.player.metric;
        return metric
          .toString()
          .split("")
          .map((digit) => `${digit}️⃣`)
          .join("");
      })
      .join("\n");
    const resultString = `${teamID} LineUP ${new Date().toLocaleDateString()}\n\n${emojiResults}\n${metricValues}\n\nPlay today at teamtrivia.io`;
    return resultString;
  };

  const { wins, losses, currentStreak, maxStreak, noStats } =
    calculateTeamStats(teamResults, selectedTeam, "lineup");

  const handleCopy = () => {
    navigator.clipboard.writeText(generateResultString());
    setShowCopiedNotification(true);
    setTimeout(() => setShowCopiedNotification(false), 2000);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2>{gameStatus === "win" ? "Goal!" : "Incorrect, Back to the bench"}</h2>
      <NextGame />
      <div className='toggle-button'>
        <button
          className={selectedTeam === teamID ? "selected" : ""}
          onClick={() => setSelectedTeam(teamID)}
        >
          {teamID}
        </button>
        <button
          className={selectedTeam === "all" ? "selected" : ""}
          onClick={() => setSelectedTeam("all")}
        >
          All Teams
        </button>
      </div>
      {noStats ? (
        <p>No stats available.</p>
      ) : (
        <div className='stats-container'>
          <div className='stats-item'>
            <div className='stats-label'>Total Record</div>
            <div className='stats-value'>
              {wins} - {losses}
            </div>
          </div>
          <div className='stats-item'>
            <div className='stats-label'>Current Streak</div>
            <div className='stats-value'>{currentStreak}</div>
          </div>
          <div className='stats-item'>
            <div className='stats-label'>Max Streak</div>
            <div className='stats-value'>{maxStreak}</div>
          </div>
        </div>
      )}
      <button onClick={handleCopy}>
        {showCopiedNotification ? "Copied!" : "Share Results"}
      </button>
    </Modal>
  );
}

export default LineupStatsModal;
