const teamCodes = {
  ANA: {
    name: "Anaheim Ducks",
    shortName: "Ducks",
  },
  ARI: {
    name: "Arizona Coyotes",
    shortName: "Coyotes",
  },
  BOS: {
    name: "Boston Bruins",
    shortName: "Bruins",
  },
  BUF: {
    name: "Buffalo Sabres",
    shortName: "Sabres",
  },
  CAR: {
    name: "Carolina Hurricanes",
    shortName: "Hurricanes",
  },
  CBJ: {
    name: "Columbus Blue Jackets",
    shortName: "Blue Jackets",
  },
  CGY: {
    name: "Calgary Flames",
    shortName: "Flames",
  },
  CHI: {
    name: "Chicago Blackhawks",
    shortName: "Blackhawks",
  },
  COL: {
    name: "Colorado Avalanche",
    shortName: "Avalanche",
  },
  DAL: {
    name: "Dallas Stars",
    shortName: "Stars",
  },
  DET: {
    name: "Detroit Red Wings",
    shortName: "Red Wings",
  },
  EDM: {
    name: "Edmonton Oilers",
    shortName: "Oilers",
  },
  FLA: {
    name: "Florida Panthers",
    shortName: "Panthers",
  },
  LAK: {
    name: "Los Angeles Kings",
    shortName: "Kings",
  },
  MIN: {
    name: "Minnesota Wild",
    shortName: "Wild",
  },
  MTL: {
    name: "Montréal Canadiens",
    shortName: "Canadiens",
  },
  NJD: {
    name: "New Jersey Devils",
    shortName: "Devils",
  },
  NSH: {
    name: "Nashville Predators",
    shortName: "Predators",
  },
  NYI: {
    name: "New York Islanders",
    shortName: "Islanders",
  },
  NYR: {
    name: "New York Rangers",
    shortName: "Rangers",
  },
  OTT: {
    name: "Ottawa Senators",
    shortName: "Senators",
  },
  PHI: {
    name: "Philadelphia Flyers",
    shortName: "Flyers",
  },
  PIT: {
    name: "Pittsburgh Penguins",
    shortName: "Penguins",
  },
  SEA: {
    name: "Seattle Kraken",
    shortName: "Kraken",
  },
  SJS: {
    name: "San Jose Sharks",
    shortName: "Sharks",
  },
  STL: {
    name: "St. Louis Blues",
    shortName: "Blues",
  },
  TBL: {
    name: "Tampa Bay Lightning",
    shortName: "Lightning",
  },
  TOR: {
    name: "Toronto Maple Leafs",
    shortName: "Maple Leafs",
  },
  VAN: {
    name: "Vancouver Canucks",
    shortName: "Canucks",
  },
  VGK: {
    name: "Vegas Golden Knights",
    shortName: "Golden Knights",
  },
  WPG: {
    name: "Winnipeg Jets",
    shortName: "Jets",
  },
  WSH: {
    name: "Washington Capitals",
    shortName: "Capitals",
  },
};

const teamColors = {
  ANA: {
    primaryColor: "#F47A38",
    secondaryColor: "#B9975B",
    textColor: "#000000",
  },
  ARI: {
    primaryColor: "#8C2633",
    secondaryColor: "#E2D6B5",
    textColor: "#FFFFFF",
  },
  BOS: {
    primaryColor: "#FFB81C",
    secondaryColor: "#000000",
    textColor: "#000000",
  },
  BUF: {
    primaryColor: "#003087",
    secondaryColor: "#FFB81C",
    textColor: "#FFFFFF",
  },
  CAR: {
    primaryColor: "#CE1126",
    secondaryColor: "#A4A9AD",
    textColor: "#FFFFFF",
  },
  CBJ: {
    primaryColor: "#002654",
    secondaryColor: "#CE1126",
    textColor: "#FFFFFF",
  },
  CGY: {
    primaryColor: "#D2001C",
    secondaryColor: "#D2001C",
    textColor: "#FFFFFF",
  },
  CHI: {
    primaryColor: "#CF0A2C",
    secondaryColor: "#000000",
    textColor: "#FFFFFF",
  },
  COL: {
    primaryColor: "#6F263D",
    secondaryColor: "#236192",
    textColor: "#FFFFFF",
  },
  DAL: {
    primaryColor: "#006847",
    secondaryColor: "#8F8F8C",
    textColor: "#FFFFFF",
  },
  DET: {
    primaryColor: "#CE1126",
    secondaryColor: "#FFFFFF",
    textColor: "#FFFFFF",
  },
  EDM: {
    primaryColor: "#041E42",
    secondaryColor: "#FF4C00",
    textColor: "#FFFFFF",
  },
  FLA: {
    primaryColor: "#041E42",
    secondaryColor: "#C8102E",
    textColor: "#FFFFFF",
  },
  LAK: {
    primaryColor: "#111111",
    secondaryColor: "#A2AAAD",
    textColor: "#FFFFFF",
  },
  MIN: {
    primaryColor: "#A6192E",
    secondaryColor: "#154734",
    textColor: "#FFFFFF",
  },
  MTL: {
    primaryColor: "#AF1E2D",
    secondaryColor: "#192168",
    textColor: "#FFFFFF",
  },
  NJD: {
    primaryColor: "#CE1126",
    secondaryColor: "#000000",
    textColor: "#FFFFFF",
  },
  NSH: {
    primaryColor: "#FFB81C",
    secondaryColor: "#FFFFFF",
    textColor: "#041E42",
  },
  NYI: {
    primaryColor: "#00539B",
    secondaryColor: "#F47D30",
    textColor: "#FFFFFF",
  },
  NYR: {
    primaryColor: "#0038A8",
    secondaryColor: "#CE1126",
    textColor: "#FFFFFF",
  },
  OTT: {
    primaryColor: "#DA1A32",
    secondaryColor: "#000000",
    textColor: "#FFFFFF",
  },
  PHI: {
    primaryColor: "#F74902",
    secondaryColor: "#000000",
    textColor: "#000000",
  },
  PIT: {
    primaryColor: "#000000",
    secondaryColor: "#CFC493",
    textColor: "#FFFFFF",
  },
  SEA: {
    primaryColor: "#001628",
    secondaryColor: "#E9072B",
    textColor: "#99D9D9",
  },
  SJS: {
    primaryColor: "#006D75",
    secondaryColor: "#EA7200",
    textColor: "#FFFFFF",
  },
  STL: {
    primaryColor: "#002F87",
    secondaryColor: "#FCB514",
    textColor: "#FFFFFF",
  },
  TBL: {
    primaryColor: "#002868",
    secondaryColor: "#000000",
    textColor: "#FFFFFF",
  },
  TOR: {
    primaryColor: "#00205B",
    secondaryColor: "#FFFFFF",
    textColor: "#FFFFFF",
  },
  VAN: {
    primaryColor: "#00205B",
    secondaryColor: "#00843D",
    textColor: "#FFFFFF",
  },
  VGK: {
    primaryColor: "#B4975A",
    secondaryColor: "#333F42",
    textColor: "#000000",
  },
  WPG: {
    primaryColor: "#041E42",
    secondaryColor: "#AC162C",
    textColor: "#FFFFFF",
  },
  WSH: {
    primaryColor: "#041E42",
    secondaryColor: "#C8102E",
    textColor: "#FFFFFF",
  },
};

export default teamCodes;
export { teamColors };
