import React, { useState, useEffect } from "react";
import GuessItem from "./GuessItem";
import StatisticsModal from "./StatisticsModal";
import "./guess.css";
import { getCurrentLocalDate } from "../../utils/dateUtils";
import axios from "axios";
import { updateTeamResults } from "../../utils/gameUtils";

function GuessList({
  refreshGuesses,
  maxGuesses,
  onNumGuessesChange,
  teamID,
  level,
}) {
  const [guesses, setGuesses] = useState([]);
  const [gameStatusByTeam, setGameStatusByTeam] = useState({});
  const [showStatisticsModal, setShowStatisticsModal] = useState(false);
  const [modalShown, setModalShown] = useState(false);

  useEffect(() => {
    const currentDate = getCurrentLocalDate();
    const storedGuesses = JSON.parse(localStorage.getItem("guesses")) || {};
    const teamGuesses = storedGuesses[currentDate]?.[level]?.[teamID] || [];

    setGuesses(teamGuesses);
    onNumGuessesChange(teamGuesses);

    const isWin = teamGuesses.some((guess) => guess && guess.clues.isCorrect);
    const numNonNullGuesses = teamGuesses.filter(
      (guess) => guess !== null
    ).length;
    const isGameOver = isWin || numNonNullGuesses >= maxGuesses;

    if (isGameOver && !modalShown) {
      setGameStatusByTeam((prevStatus) => ({
        ...prevStatus,
        [teamID]: isWin ? "win" : "loss",
      }));
      setShowStatisticsModal(true);
      setModalShown(true);
      handleGameOver(isWin);
    }
  }, [
    refreshGuesses,
    onNumGuessesChange,
    teamID,
    maxGuesses,
    modalShown,
    level,
  ]);

  const handleGameOver = async (isWin) => {
    console.log("GAME IS OVER");
    const currentDate = getCurrentLocalDate();
    const storedGuesses = JSON.parse(localStorage.getItem("guesses")) || {};
    const teamGuesses = storedGuesses[currentDate]?.[level]?.[teamID] || [];
    const gameResultPosted = localStorage.getItem(
      `gameResultPosted_${teamID}_${level}_${currentDate}`
    );

    if (!gameResultPosted) {
      console.log("IM POSTING");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/game-results`,
          {
            gameType: "answer",
            isCorrect: isWin,
            teamCode: teamID,
            level: level,
          }
        );

        updateTeamResults(teamID, isWin, teamGuesses, "guesser");

        // Fetch the team rank
        const rankResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/game-results/most-wins?timePeriod=today`
        );
        const sortedData = rankResponse.data.sort(
          (a, b) => b.correctCount - a.correctCount
        );
        const teamIndex = sortedData.findIndex(
          (item) => item.teamCode === teamID
        );
        const rank = teamIndex !== -1 ? teamIndex + 1 : null;

        setGameStatusByTeam((prevStatus) => ({
          ...prevStatus,
          [teamID]: { status: isWin ? "win" : "loss", rank },
        }));

        // Set the flag in local storage to indicate that the game result has been posted
        localStorage.setItem(
          `gameResultPosted_${teamID}_${level}_${currentDate}`,
          "true"
        );
      } catch (error) {
        console.error("Error posting game result:", error);
      }
    }
  };

  useEffect(() => {
    const currentDate = getCurrentLocalDate();
    const storedGuesses = JSON.parse(localStorage.getItem("guesses")) || {};
    const teamGuesses = storedGuesses[currentDate]?.[level]?.[teamID] || [];
    const updatedGuesses = [
      ...teamGuesses,
      ...Array(maxGuesses - teamGuesses.length).fill(null),
    ];
    setGuesses(updatedGuesses);
    onNumGuessesChange(teamGuesses);

    const guessRow = document.querySelector(
      ".guesses-list tbody tr:last-child"
    );
    if (guessRow) {
      guessRow.classList.add("animated");
    }
  }, [refreshGuesses, onNumGuessesChange, teamID, maxGuesses, level]);

  const handleShare = () => {
    setShowStatisticsModal(true);
  };

  const handleCloseModal = () => {
    setShowStatisticsModal(false);
  };

  const generateResultString = () => {
    const resultString = guesses
      .map((guess) => {
        if (guess === null) {
          return "";
        }
        const clues = guess.clues;
        const row = [
          clues.position === "Correct"
            ? "🟩"
            : clues.position === "Partial Match"
            ? "🟨"
            : "🟥",
          clues.country === "Correct"
            ? "🟩"
            : clues.country === "Correct"
            ? "🟩"
            : clues.country === "Partial Match"
            ? "🟨"
            : "🟥",
          clues.jerseyNumber === "Correct"
            ? "🟩"
            : clues.jerseyNumber === "Up"
            ? "⬆️"
            : clues.jerseyNumber === "Down"
            ? "⬇️"
            : "⬛",
          clues.firstYear === "Correct"
            ? "🟩"
            : clues.firstYear === "Up"
            ? "⬆️"
            : clues.firstYear === "Down"
            ? "⬇️"
            : "⬛",
          clues.points === "Correct"
            ? "🟩"
            : clues.points === "Up"
            ? "⬆️"
            : clues.points === "Down"
            ? "⬇️"
            : "⬛",
        ].join("");
        return row;
      })
      .filter((row) => row !== "")
      .join("\n");

    const levelText =
      level === "hard" || level === "medium"
        ? `(${level.charAt(0).toUpperCase() + level.slice(1)}) `
        : "";

    const numGuesses = guesses.filter((guess) => guess !== null).length;
    const isWin = gameStatusByTeam[teamID] === "win";
    const guessCountText = isWin ? `${numGuesses}/${maxGuesses}` : "❌";
    return `Team Trivia ${new Date().toLocaleDateString()}\n\n${teamID} Mystery Player ${levelText}\n\n${guessCountText}\n${resultString}\n\nPlay today: teamtrivia.io`;
  };

  const renderGameOverMessage = () => {
    if (gameStatusByTeam[teamID] === "win") {
      return (
        <div>
          <p>Goal!</p>
          <button className='submit-guess-btn' onClick={handleShare}>
            Share
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <p>Incorrect.</p>
          <button className='submit-guess-btn' onClick={handleShare}>
            Share
          </button>
        </div>
      );
    }
  };

  return (
    <div className='guesses-list'>
      <table className='guesser-table'>
        <thead>
          <tr>
            <th className='guess-number'>Guess</th>
            <th className='name'>Name</th>
            <th className='position'>Position</th>
            <th className='country'>Country</th>
            <th className='jersey-number'>Jersey</th>
            <th className='first-year'>First Year</th>
            <th className='points'>Points</th>
          </tr>
        </thead>
        <tbody>
          {guesses.map((guess, index) => (
            <GuessItem key={index} guess={guess} guessNumber={index + 1} />
          ))}
        </tbody>
      </table>
      {(gameStatusByTeam[teamID] === "win" ||
        guesses.filter((guess) => guess !== null).length === maxGuesses) && (
        <div className='game-over'>{renderGameOverMessage()}</div>
      )}
      <StatisticsModal
        isOpen={showStatisticsModal}
        onClose={handleCloseModal}
        gameStatus={gameStatusByTeam[teamID]?.status}
        generateResultString={generateResultString}
        teamID={teamID}
        maxGuesses={maxGuesses}
        rank={gameStatusByTeam[teamID]?.rank}
      />
    </div>
  );
}

export default GuessList;
