import React, { useState, useEffect } from "react";
import axios from "axios";
import Navigation from "./components/global/navigation";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import "./components/playerRanking/playerRanking.css";
import RankingBubble from "./components/playerRanking/rankingBuble";
import {
  updateTotalRecord,
  updateTeamResults,
  hasPlayedGame,
  hasHelperModalBeenDismissed,
} from "./utils/gameUtils";
import { sanitizeMetric } from "./utils/metricUtils";
import {
  getStoredGuesses,
  setStoredGuesses,
  clearOldGuesses,
} from "./utils/playerRankingUtils";
import { playerRankingInstructions } from "./constants/gameInstructions";
import PlayerRankingStatsModal from "./components/playerRanking/playerRankingStatsModal";
import teamCodes, { teamColors } from "./constants/teamCodes";

const PlayerRankingGame = () => {
  const { teamID } = useParams();
  const [data, setData] = useState(null);
  const [playerOrder, setPlayerOrder] = useState([]);
  const [correctOrder, setCorrectOrder] = useState([]);
  const [isGameOver, setIsGameOver] = useState(false);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [gameStatus, setGameStatus] = useState(null);
  const [showHelperModal, setShowHelperModal] = useState(false);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/player-ranking-game/${teamID}/players`
        );
        setData(response.data);
        clearOldGuesses();
        // Load stored guesses
        const storedGuesses = getStoredGuesses("playerRankingGuess", teamID);
        if (storedGuesses.guesses && storedGuesses.guesses.length > 0) {
          const storedPlayerOrder = storedGuesses.guesses.map(
            (guess) => guess.player.id
          );
          setPlayerOrder(storedPlayerOrder);
          setIsGameOver(true);
          setGameStatus(storedGuesses.isCorrect ? "win" : "loss");
          setCorrectOrder(storedGuesses.guesses);
        } else {
          if (
            !hasPlayedGame("playerRanking") &&
            !hasHelperModalBeenDismissed("playerRanking")
          ) {
            setShowHelperModal(true);
          }
          setPlayerOrder(response.data.players.map((player) => player.id));
        }
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    };

    fetchPlayers();
  }, [teamID]);

  useEffect(() => {
    if (isGameOver) {
      const storedGuesses = getStoredGuesses("playerRankingGuess", teamID);
      if (storedGuesses.guesses && storedGuesses.guesses.length > 0) {
        const correctOrder = storedGuesses.guesses.map((guess) => ({
          id: guess.player.id,
          firstName: guess.player.firstName,
          lastName: guess.player.lastName,
          metric: guess.player.metric,
          playerCorrect: guess.isCorrect,
          correctPosition: guess.player.correctPosition,
        }));
        setCorrectOrder(correctOrder);
      }
    }
  }, [isGameOver, teamID]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(playerOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPlayerOrder(items);
  };

  const handleSubmitOrder = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/player-ranking-game/${teamID}/check-order`,
        { playerIds: playerOrder }
      );
      const { correctOrder } = response.data;
      setCorrectOrder(correctOrder);
      const allCorrect = correctOrder.every((player) => player.playerCorrect);
      setGameStatus(allCorrect ? "win" : "loss");
      // Store the game results
      const guesses = correctOrder.map((player) => ({
        player: {
          id: player.id,
          firstName: player.firstName,
          lastName: player.lastName,
          metric: player.metric,
          correctPosition: player.correctPosition,
        },
        isCorrect: player.playerCorrect,
      }));

      setStoredGuesses("playerRankingGuess", guesses, teamID);
      updateTotalRecord(
        correctOrder.every((player) => player.playerCorrect),
        "playerRanking"
      );
      updateTeamResults(
        teamID,
        correctOrder.every((player) => player.playerCorrect),
        guesses,
        "playerRanking"
      );

      setIsGameOver(true);
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };

  const handleShare = () => {
    setShowStatsModal(true);
  };

  return (
    <div className='page-container'>
      <Navigation
        teamID={teamID}
        basePath='/player-ranking-game'
        instructions={playerRankingInstructions()}
        showHelperModal={showHelperModal}
        setShowHelperModal={setShowHelperModal}
        gameType='playerRanking'
      />
      <div
        className='gradient-background-playerRanking'
        style={{
          background: `linear-gradient(120deg, ${
            teamColors[teamID] ? teamColors[teamID].primaryColor : "#0038ff"
          }, ${
            teamColors[teamID] ? teamColors[teamID].secondaryColor : "#0038ff"
          })`,
        }}
      ></div>
      <div className='container'>
        <h1>Player Ranking Game</h1>
        {data && (
          <>
            <p className='game-explainer'>
              Order players by{" "}
              <strong>
                {data.metric === "birthYear"
                  ? "age, youngest to oldest"
                  : sanitizeMetric(data.metric)}
              </strong>{" "}
              {!["birthYear", "jerseyNumber"].includes(data.metric) &&
                `with the ${teamCodes[teamID].shortName}`}
            </p>
            {gameStatus && (
              <h2 className={`game-status ${gameStatus}`}>
                {gameStatus === "win" ? (
                  "Goal!"
                ) : (
                  <span>
                    Shot Wide
                    <span className='try-again-text'>
                      come back tomorrow to try again.
                    </span>
                  </span>
                )}
              </h2>
            )}
            <DragDropContext onDragEnd={isGameOver ? null : handleDragEnd}>
              <Droppable droppableId='players'>
                {(provided) => (
                  <ul
                    className='player-list'
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {playerOrder.map((playerId, index) => {
                      const player = data.players.find(
                        (p) => p.id === playerId
                      );
                      if (!player) return null; // Skip rendering if player is not found
                      return (
                        <Draggable
                          key={player.id}
                          draggableId={player.id.toString()}
                          index={index}
                          isDragDisabled={isGameOver}
                        >
                          {(provided) => (
                            <li
                              className='player-card'
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <RankingBubble
                                player={player}
                                rank={index + 1}
                                isCorrect={
                                  correctOrder.find((p) => p.id === player.id)
                                    ?.playerCorrect ?? null
                                }
                                metric={
                                  correctOrder.find((p) => p.id === player.id)
                                    ?.metric || ""
                                }
                                correctPosition={
                                  correctOrder.find((p) => p.id === player.id)
                                    ?.correctPosition || ""
                                }
                              />
                              {player.firstName} {player.lastName}
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <div className='button-container'>
              {!isGameOver && (
                <button
                  className='submit-guess-btn'
                  onClick={handleSubmitOrder}
                  disabled={isGameOver}
                >
                  Submit Guess
                </button>
              )}
              {isGameOver && (
                <button className='submit-guess-btn' onClick={handleShare}>
                  Share Results
                </button>
              )}
            </div>
            {showStatsModal && (
              <PlayerRankingStatsModal
                isOpen={showStatsModal}
                onClose={() => setShowStatsModal(false)}
                gameStatus={gameStatus}
                teamID={teamID}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PlayerRankingGame;
