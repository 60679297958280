import React from "react";
import "./guess.css";
import { formatPosition } from "../../utils/positionUtils";

function GuessItem({ guess, guessNumber }) {
  if (guess === null) {
    return (
      <tr className={guess ? "animated" : ""}>
        <td className='guess-number'># {guessNumber}</td>
        <td className='name'></td>
        <td className='position'></td>
        <td className='country'></td>
        <td className='jersey-number'></td>
        <td className='first-year'></td>
        <td className='points'></td>
      </tr>
    );
  }

  const getColor = (clue, isCorrect) => {
    if (isCorrect) {
      return "green";
    } else if (clue === "Exact Match") {
      return "green";
    } else if (clue === "Correct") {
      return "green";
    } else if (clue === "No Match") {
      return "red";
    } else if (clue === "Partial Match") {
      return "yellow";
    }
    return "";
  };

  const getArrow = (clue, isCorrect) => {
    if (isCorrect) {
      return "";
    } else if (clue === "Up") {
      return "↑";
    } else if (clue === "Down") {
      return "↓";
    }
    return "";
  };

  const { clues } = guess;
  const isCorrect = clues.isCorrect;

  return (
    <tr>
      <td className='guess-number'># {guessNumber}</td>
      <td className={`name ${isCorrect ? "green animated" : "animated"}`}>
        {guess.player.name}
      </td>
      <td
        className={`position ${getColor(clues.position, isCorrect)} animated`}
      >
        {formatPosition(guess.player.position)}
      </td>
      <td className={`country ${getColor(clues.country, isCorrect)} animated`}>
        {guess.player.country}
      </td>
      <td
        className={`jersey-number ${getColor(
          clues.jerseyNumber,
          isCorrect
        )} animated`}
      >
        {guess.player.jerseyNumber}
        <strong>{getArrow(clues.jerseyNumber, isCorrect)}</strong>
      </td>
      <td
        className={`first-year ${getColor(
          clues.firstYear,
          isCorrect
        )} animated`}
      >
        {guess.player.firstYear}
        <strong>{getArrow(clues.firstYear, isCorrect)}</strong>
      </td>
      <td className={`points ${getColor(clues.points, isCorrect)} animated`}>
        {guess.player.points}
        <strong>{getArrow(clues.points, isCorrect)}</strong>
      </td>
    </tr>
  );
}

export default GuessItem;
